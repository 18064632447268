<template><div><h1 id="booting-the-recovery-usb" tabindex="-1"><a class="header-anchor" href="#booting-the-recovery-usb"><span>Booting the Recovery USB</span></a></h1>
<div class="custom-container danger"><p class="custom-container-title">WARNING</p>
<p>The recovery process will wipe <strong>all</strong> data on the internal drive.</p>
</div>
<ol>
<li>
<p>Boot the device to <RouteLink to="/docs/boot-modes/recovery.html">Recovery Mode</RouteLink>.</p>
</li>
<li>
<p>Plug in your recovery USB drive when prompted.</p>
</li>
<li>
<p>The recovery process will start automatically.</p>
</li>
<li>
<p>If returning the device to stock, exit Developer Mode</p>
</li>
</ol>
<div class="custom-container warning"><p class="custom-container-title">NOTE</p>
<p>If the recovery process fails, and you disabled Firmware Write Protection via battery disconnect, you will need to again disconnect the battery in order for the recovery process to complete successfully.</p>
</div>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>The recovery process creates logs on the recovery USB, in a small (~10MB) Linux-formatted partition.
These logs can be useful in diagnosing a failed recovery.</p>
</div>
</div></template>


